@use '@/styles/utils/mixins.scss' as *;
.serviceSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .sectionHeadingContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: space-between;
  }
  .sectionDescription {
    max-width: 35rem;
  }
  .cardsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
